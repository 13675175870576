import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Logger Type" } }, [_c("text-input", { key: "description", attrs: { "label": "Description", "placeholder": "Type Description", "max-length": 30, "form-item": "", "rules": "required", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditLoggerType"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditLoggerType = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-logger-type" }, [_c("resource", { attrs: { "name": "sterilization.logger-types", "api-url": _vm.apiUrl, "edit": _vm.EditLoggerType, "redirect-route": "/sterilization-audits/logger-type" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditLoggerType",
  data() {
    return {
      EditLoggerType,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
